/* for sidenav to take a whole page */

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
}

@media screen and (min-width: 600px) {
    .ocultarMenu {
        display: flex;
        justify-content: flex-end;
    }
    .ocultarBoton {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .ocultarMenu {
        display: none;
        justify-content: flex-end;
    }
    .ocultarBoton {
        display: flex;
    }
}

mat-drawer-content {
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

mat-drawer {
    width: 200px;
    padding: 5px;
}

mat-grid-tile {
    background: lightblue;
}

a {
    cursor: pointer
}


/*
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~mdb-angular-ui-kit/assets/scss/mdb.scss';
*/

.btn-scrollUp {
    position: fixed;
    bottom: 60px;
    right: 30px;
    background-color: #fb6a00ad;
}

.btn-scrollUp:hover {
    background-color: rgb(255, 81, 0);
}

.material-icons {
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}